<script></script>

<template>
  <tairo-sidebar-layout>
    <!--    <BaseBreadcrumb />-->
    <slot />
  </tairo-sidebar-layout>
</template>

<style>
:root {
  --p-accordion-header-background: var(--color-muted-50) !important;
  --p-accordion-header-active-background: var(--color-primary-500) !important;
  --p-accordion-header-active-hover-background: var(--color-primary-500) !important;

  .p-accordionpanel:nth-of-type(odd) {
    --p-accordion-header-background: var(--color-muted-100) !important;
  }
}

html.dark {
  --p-accordion-header-background: var(--color-muted-800) !important;

  .p-accordionpanel:nth-of-type(odd) {
    --p-accordion-header-background: var(--color-muted-900) !important;
  }

  --p-accordion-content-background: var(--color-muted-800) !important;
  --p-tabs-tabpanel-background: var(--color-muted-800) !important;
  --p-tabs-tablist-background: var(--color-muted-800) !important;
}

.p-autocomplete .p-inputtext {
  width: 100%;
}

html.light .jexcel > tbody > tr > td.readonly {
  color: black !important;
}

html.dark .jexcel > tbody > tr > td.readonly {
  color: white !important;
}

html.dark .jexcel tbody .jexcel_freezed {
  position: relative;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
  border-right: 2px white;
  -webkit-box-shadow: 2px 0px 0px 0px rgba(255, 255, 255, 1) !important;
  -moz-box-shadow: 2px 0px 0px 0px rgba(255, 255, 255, 1) !important;
  box-shadow: 2px 0px 0px 0px rgba(255, 255, 255, 1) !important;
}

.jexcel_content {
  box-shadow: none !important;
}
</style>
